import * as React from "react";
import HeardTopWrapper from "../components/header-wrapper";
import Layout from "../components/layout/index";
import PageContainer from "../components/page-container";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image, Carousel } from "react-bootstrap";
import featureImg1 from "../images/feature-icon1.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
const Features = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeardTopWrapper title="Features" />
      <Layout>
        <Helmet>
          <title>{t("myKitchenInspector")}｜Features</title>
        </Helmet>
        <PageContainer>
          <Container>
            <h5 className="feature-sub-title">
              <span className="feature-sub-title-line"></span>
              <span className="feature-text">Features</span>
            </h5>
            <h3 className="feature-title">
              List of features <br />
              with description
            </h3>
            <Row>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img pink-color">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img pink-color">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="feature-line" />
            <h5 className="feature-sub-title">
              <span className="feature-sub-title-line"></span>
              <span className="feature-text">More Features</span>
            </h5>
            <h3 className="feature-title">How it works</h3>
            <Row>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img pink-color">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img pink-color">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="feature-line" />
            <h3 className="feature-title">
              Benefits of using
              <br /> My Kitchen
            </h3>
            <Row>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img pink-color">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="feature-item">
                  <div className="feature-item-img pink-color">
                    <Image src={featureImg1} />
                  </div>
                  <div className="feature-item-des">
                    <h5>Lorem lpsum Dolor Sit</h5>
                    <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="feature-line" />
            <h3 className="feature-title">Responsibilities of a Admin</h3>
            <Row>
              <Col>
                <Carousel className="feature-carousel">
                  <Carousel.Item>
                    <div className="carousel-img">
                      <img
                        className="d-block"
                        src={featureImg1}
                        alt="Third slide"
                      />
                    </div>
                    <Carousel.Caption>
                      <div className="feature-item">
                        <div className="feature-item-img">
                          <Image src={featureImg1} />
                        </div>
                        <div className="feature-item-des">
                          <h5>Lorem lpsum Dolor Sit</h5>
                          <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                        </div>
                      </div>
                      <div className="feature-item">
                        <div className="feature-item-img">
                          <Image src={featureImg1} />
                        </div>
                        <div className="feature-item-des">
                          <h5>Lorem lpsum Dolor Sit</h5>
                          <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                        </div>
                      </div>
                      <div className="feature-item">
                        <div className="feature-item-img">
                          <Image src={featureImg1} />
                        </div>
                        <div className="feature-item-des">
                          <h5>Lorem lpsum Dolor Sit</h5>
                          <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-img">
                      <img
                        className="d-block"
                        src={featureImg1}
                        alt="Third slide"
                      />
                    </div>

                    <Carousel.Caption>
                      <div className="feature-item">
                        <div className="feature-item-img">
                          <Image src={featureImg1} />
                        </div>
                        <div className="feature-item-des">
                          <h5>Lorem lpsum Dolor Sit</h5>
                          <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                        </div>
                      </div>
                      <div className="feature-item">
                        <div className="feature-item-img">
                          <Image src={featureImg1} />
                        </div>
                        <div className="feature-item-des">
                          <h5>Lorem lpsum Dolor Sit</h5>
                          <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-img">
                      <img
                        className="d-block"
                        src={featureImg1}
                        alt="Third slide"
                      />
                    </div>

                    <Carousel.Caption>
                      <div className="feature-item">
                        <div className="feature-item-img">
                          <Image src={featureImg1} />
                        </div>
                        <div className="feature-item-des">
                          <h5>Lorem lpsum Dolor Sit</h5>
                          <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                        </div>
                      </div>
                      <div className="feature-item">
                        <div className="feature-item-img">
                          <Image src={featureImg1} />
                        </div>
                        <div className="feature-item-des">
                          <h5>Lorem lpsum Dolor Sit</h5>
                          <p>Lorem lpsum Dolor SitLorem lpsum Dolor Sit</p>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </PageContainer>
      </Layout>
    </>
  );
};
export default Features;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
